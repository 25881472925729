<script setup lang="ts">
import {CmsSectionDefault} from "@shopware-pwa/composables-next";

const props = defineProps<{
    content: CmsSectionDefault;
}>();

const getBlockStyle = (block) => {
    if (block.type === "text-on-image") {
        return 'background-size: ' + block.backgroundMediaMode + '; background-position: center; background-repeat: no-repeat;'
    }
    return '';
}
const sectionId = computed(() => {
    if (props.content.name) {
        return 'section-' + props.content.name;
    }
    return '';
});
</script>

<template>
<div class="cms-section-default relative" :id="sectionId">
    <div :class="{'container': props.content.sizingMode == 'boxed'}">
        <ClientOnly v-if="content.cssClass?.includes('client-only')">
            <CmsGenericBlock
                v-for="cmsBlock in content.blocks"
                class="overflow-y-visible"
                :style="getBlockStyle(cmsBlock)"
                :key="cmsBlock.id"
                :content="cmsBlock"
            />
        </ClientOnly>
        <CmsGenericBlock
            v-else
            v-for="cmsBlock in content.blocks"
            class="overflow-y-visible"
            :style="getBlockStyle(cmsBlock)"
            :key="cmsBlock.id"
            :content="cmsBlock"
        />
    </div>
</div>
</template>
<style>
.cms-section-default {
    scroll-margin-top: 90px;

    @media (min-width: 768px) {
        scroll-margin-top: 103px;

    }

    @media (min-width: 1280px) {
        scroll-margin-top: 111px;
    }
}
</style>
